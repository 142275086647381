// CustomSiliconWafer.scss

.custom-silicon-wafer-section {
    background-color: #2d8ccb;
    color: #fff;
    padding: 4rem;
    position: relative;
    .custom-silicon-wafer-container {
        background-image: url('../../../../images/backgroundImg/light-gray-texture-background_149326-9672.png');
        opacity: 0.3;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; 
    }
    .custom-silicon-wafer {
        z-index: 2 !important;
        .custom-silicon-wafer-title {
            text-align: center;
            h1 {
                font-size: 2.5rem;
                margin: 2rem;
                margin-top: 5px;            
            }
        }
        .custom-silicon-wafer-div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0rem;
            .image {
                img {
                    display: block;
                    width: 90%;
                    margin: auto;
                    z-index: 2 !important;
                }
            }
            .text {
                p {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media (min-width: 1199px) {
    .custom-silicon-wafer-section {
      .custom-silicon-wafer {
        max-width: 1000px;
        margin: auto;
      }
    }
}

@media (max-width: 801px) {
    .custom-silicon-wafer-section {
        padding: 4rem 2rem;
        .custom-silicon-wafer {
            z-index: 2 !important;
            .custom-silicon-wafer-div {
                display: block;
                .image {
                    img {
                        width: 20rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 401px) {
    .custom-silicon-wafer-section {
        padding: 4rem 1.5rem;
        .custom-silicon-wafer {
            z-index: 2 !important;
            .custom-silicon-wafer-div {
                display: block;
                .image {
                    img {
                        width: 90%;
                    }
                }
                .text {
                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}