//Banner.scss
.banner-area {
  position: relative;
  overflow: hidden; // Hide any overflow from the image
  background-color: #000;
}

.banner-area {
  .swiper-pagination {

    .swiper-pagination-bullet {
      background-color: white; // Set the bullet color to white
      margin: 1.5rem 8px;
      //float: right;

      &-active {
        background-color: #2d8ccb; // Set the active bullet color to white
      }
    }

    // Optional: To change the pagination bullet opacity
    .swiper-pagination-bullet {
      opacity: 0.5;

      &-active {
        opacity: 1;
      }
    }
  }
}

.banner-img img {
  width: 100%; // Make the image cover the full width
  height: auto; // Maintain the aspect ratio
  display: inline; // Remove any extra space below the image
  max-height: 28rem;
}

.banner-slider .banner-slide {
  position: relative;
  max-width: 2000px;
  margin: auto;
}

.banner-cont {
  //color: var(--white);
  max-width: 100%;
  line-height: 1.6;
  position: absolute; // Position the text absolutely within the banner
  top: 55%;
  left: 50%;
  transform: translate(-70%, -49%); // Center the text
  z-index: 3;
  padding: 0 20px; // Add some padding for better readability
  text-align: left;
  color: #fff;
  width: 50.35%;
}

.banner-cont h1,
.banner-cont p,
.banner-cont .p1 {
  text-align: left; // left align text within the container
}

.p1 {
  margin-top: 20px;
}

.banner-cont::before {
  content: "";
  position: absolute;
  top: 45%; // Adjust the vertical position as needed
  left: -100px; // Adjust the left position
  transform: translateY(-50%); // Center vertically
  z-index: -1;
  background: linear-gradient(137deg, rgba(53, 172, 250, 0.34) 31.2%, rgba(102, 111, 151, 0.34) 49.35%);
  width: 30rem; // Adjust width for mobile view
  height: 30rem; // Adjust height for mobile view
  border-radius: 50%; // Make it a circle
  overflow: hidden; // Ensure no overflow
}

.banner-cont h1 {
  color: var(--white);
  font-size: 3rem;
  margin: 0px;
  margin-top: -1.1rem;
}

.banner-cont h1 span {
  display: block;
  -webkit-text-stroke: 1px #ffffff;
  //text-stroke: 1px #ffffff;
  color: transparent;
  font-size: 3rem;
  margin-bottom: -1rem;
}

.banner-cont h1 .banner-title {
  color: var(--white);
  line-height: 85%;
  margin: 20px 0px;
  //margin-top: 30px;
}

.banner-cont p {
  text-transform: capitalize;
  font-size: 1.1rem;
  margin-top: 0px;
}

.banner-cont .p1 {
  font-size: 1.5rem;
  line-height: 0.9;
  letter-spacing: 4.8px;
  //margin-bottom: 30px;
  position: relative;
}

.banner-cont .p1::after {
  content: "";
  display: inline-block;
  width: 127px;
  height: 1px;
  display: inline-block;
  background: linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0) 62.2%);
  opacity: 0.7;
  margin-left: 18px;
  vertical-align: middle;
}

// .banner-cont .learnMore {

// }
////////
.banner-cont {
  button {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    //border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: 12px 30px;
    min-width: 163px;
    //border-radius: 26px;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: 2px solid #fff;

    i {
      margin-left: 10px;
      color: #2d8ccb;
    }

    &:hover {
      background-color: #2d8ccb;
      color: #fff;
      //border: #fff;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: all .3s ease-in-out;
      text-transform: capitalize;
    }
  }
}

/* Media Queries for Mobile */
@media (max-width: 1830px) {
  .banner-cont .p1 {
    font-size: 2rem;
  }

  .banner-cont h1 span {
    font-size: 4rem;
    margin-bottom: -2vw;
  }

  .banner-cont h1 {
    font-size: 4rem;
  }

  // .banner-cont p {
  //   font-size: 1.3vw;
  // }
  .banner-cont button {
    width: 15vw;
    //font-size: 1.1vw;
  }

  .banner-cont p {
    text-transform: capitalize;
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .banner-img img {
    width: 100%;
    height: 36rem;
    display: block;
    max-height: 36rem;
    object-fit: cover;
    /* Ensures the image covers the area without stretching */
    object-position: center;
    /* Centers the image within the container */
    filter: brightness(0.6);
  }

}

@media (max-width: 1087px) {
  .banner-cont {
    transform: translate(-75%, -49%);
    h1 .banner-title {
      line-height: 100%;
      margin: 10px 0px;
    }
  }
}

@media (max-width: 1028px) {
  .banner-cont {
    width: 60%;
    left: 60%;
  }
}

//@media (max-width: 829px) {
  @media (max-width: 862px) {

  .banner-img img {
    width: 100%;
    height: 32rem;
    object-fit: cover;
    object-position: center;
  }

  .banner-cont {
    max-width: 700px;
    width: 85%;
    transform: translate(-60%, -55%);
    padding-right: 0px;
  }

  .banner-cont::before {
    width: 20rem; // Specific width for screens under 825px
    height: 20rem; // Specific height for screens under 825px
    top: 34%;
    left: -30px; // Adjust to fit better within the container
  }

  .banner-cont .p1 {
    font-size: 1.24rem;
  }

  .banner-cont h1 span {
    font-size: 2.5rem;
    margin-bottom: -10px;
  }

  .banner-cont h1 {
    font-size: 2.5rem;
    margin-top: -6px;
  }

  .banner-cont p {
    text-transform: capitalize;
    font-size: 0.9rem;
    margin-top: 10px;
    width: 90%;
  }

  .banner-cont button {
    width: 10rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 394px) {
  .banner-cont .p1::after {
    display: none;
  }
}
@media (max-width: 379px) {
  .banner-cont {
    transform: translate(-60%, -53%);
  }
}
@media (max-width: 365px) {
  .banner-img img {
    height: 36rem;
  }
  .banner-cont {
    transform: translate(-60%, -55%);
  }
}