//ShopOnlineSec.scss
.shop-online-sec {
  //background-color: #e8f6ff;
  background-color: #e8f2ff;
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  margin-top: 5rem;
  color: #53778f;

  .swiper-horizontal {
    width: 70%;
    height: auto;
  }

  .blur-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    max-width: 310px;
    width: 29%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    //--bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;

    button {
      background-color: #1E3A8A;
      color: #fff;
      padding: 10px 20px;
      //border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 3rem;
      border: 2px solid #1E3A8A;
      margin-bottom: 0rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      padding: 16px 30px;
      min-width: 163px;
      //border-radius: 26px;
      text-align: center;
      overflow: hidden;
      position: relative;

      i {
        margin-left: 10px;
      }

      &:hover {
          background-color: #fff;
          color: #1E3A8A;
          //border: #fff;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          -ms-transition: .3s ease-in-out;
          -o-transition: .3s ease-in-out;
          transition: all .3s ease-in-out;
          text-transform: capitalize;
      }
    }
  }

  .center-cont {
    text-align: center;
    //margin-bottom: 40px;
    margin-bottom: 46px;
    max-width: 942px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    h2 {
      font-weight: 400;
      font-size: 40px;
      line-height: 1;
      font-family: 'Arial', sans-serif;
      font-weight: bold;
      position: relative;
      text-transform: capitalize;
      margin: 0 0 25px;
      padding: 0;
      color: #02263e;
    }

    h2::before {
      content: "";
      display: inline-block;
      width: 127px;
      height: 1px;
      display: inline-block;
      background: linear-gradient(-90deg, #2d8ccb 0, rgba(255, 255, 255, 0) 62.2%);
      opacity: .7;
      margin-right: 18px;
      vertical-align: middle;
    }

    h2::after {
      content: "";
      display: inline-block;
      width: 127px;
      height: 1px;
      display: inline-block;
      background: linear-gradient(90deg, #2d8ccb 0, rgba(255, 255, 255, 0) 62.2%);
      opacity: .7;
      margin-left: 18px;
      vertical-align: middle;
    }

    p {
      font-size: 1rem;
      color: #53778f;
      line-height: 1.6;
      max-width: 800px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  .cmn-slider-wrap {
    position: relative;

    .slick-slider {
      position: relative;
    }

    .slick-slider::before {
      content: "";
      position: absolute;
      bottom: -180px;
      right: -180px;
      width: 375px;
      height: 375px;
      border-radius: 50%;
      background-color: #2d8ccb;
      filter: blur(70px);
      z-index: 1;
      opacity: .2;
    }

    .slick-track {
      display: flex;
      gap: 20px;
    }

    .slick-initialized .slick-slide {
      display: block;
      margin-top: 20px;
    }

    .cmn-card {
      border-radius: 12px;
      box-shadow: 0 8px 10px 0 rgba(45, 140, 203, .16);
      min-height: 360px;
      height: 100%;
      padding: 30px 20px;
      text-align: center;
      position: relative;
      overflow: hidden;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      //overflow: hidden;
      //transition: transform 0.3s ease;

      .cmn-card-pic {
        height: 120px;
        //width: 100%;
        width: 90%;
        //margin: 0 auto 15px 0;
        margin: auto;
        margin-bottom: 15px;
  
        img {
          //object-fit: contain;
          object-fit: cover;
          width: 100%;
          height: 100%
        }
      }

      a {
        position: relative; /* Add this to make the ::after element relative to the <a> tag */
        display: block;
        font-size: 1.125rem;
        font-weight: 600;
        color: #333;
        padding: 10px 20px;
        text-decoration: none;
        transition: color 0.3s ease;
        
        &::after {
          content: '';
          position: absolute;
          top: 100%; /* Position the pseudo-element just below the <a> tag */
          left: 50%;
          transform: translateX(-50%);
          background-color: #2d8ccb;
          width: 50%; /* Adjust the width of the border */
          height: 1px; /* Thickness of the border */
        }
      
        &:hover {
          color: #007bff;
        }
      }      

      .shop-price {
        font-size: 1.125rem;
        font-weight: bold;
        color: #007bff;
        padding: 0 20px 15px;
      }
    }

    /*.cmn-card::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      background: url('../../images/Style/card-svg-top.svg') center / contain no-repeat;
      height: 46px;
      width: 157px;
      opacity: 1; // Make this always visible
    }

    .cmn-card::after {
      content: "";
      position: absolute;
      bottom: -1px;
      right: -3px;
      background: url('../../images/Style/card-svg-bottom.svg') center / contain no-repeat;
      height: 46px;
      width: 157px;
      opacity: 1; // Make this always visible
    }*/

    .shop-cards {
      min-height: 260px;
      max-width: 260px;
      margin: auto;
      margin-bottom: 6rem;

      .shop-price {
        font-size: 20px;
        font-family: var(--arialbold);
        color: #02263e;
        padding: 15px;
      }
    }
    .swiper-button-prev {
      //display: none;
      left: calc(50% - 50px);
      margin-top: 11rem;
    }
  
    .swiper-button-next {
      //display: none;
      right: calc(50% - 50px);
      margin-top: 11rem;
    }
  
    .swiper-button-prev,
    .swiper-button-next {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      box-shadow: 0 10px 13px 0 rgba(45, 140, 203, .12);
      border: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: all .3s ease-in-out;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px #00000060;
      color: #000;
    }
  
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      color: #2d8ccb;
    }
  
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 22px;
      /* Adjust the size of the arrow icon */
    }
  }
}

@media (max-width: 1439px) {
  .shop-online-sec {
    .center-cont h2 {
      font-size: 35px;
      line-height: normal;
    }
  }
}

@media (max-width: 621px) {
  .shop-online-sec {
    .center-cont h2::before {
      display: none;
    }
    .center-cont h2::after {
      display: none;
    }
  }
}

// @media (max-width: 1439px) {
//   .cmn-slider .slick-list {
//     margin: -20px -12px;
//   }
// }

// @media (min-width: 1279px) {
//   .shop-online-sec .cmn-slider-wrap {
//     .slick-slider {
//       margin-bottom: 80px;
//     }
//   }
// }

// @media (max-width: 1199px) {

//   .h2-title,
//   h2 {
//     font-size: 32px;
//   }

//   .shop-online-sec .center-cont p {
//     font-size: 16px;
//   }
// }

// @media (min-width: 992px) {
//   .container {
//     max-width: 960px;
//   }
// }

// @media (min-width: 768px) {
//   .container {
//     max-width: 720px;
//   }
// }

// @media (min-width: 504px) {
//   .container {
//     max-width: 960px;
//   }
// }