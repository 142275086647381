// ContactSection.jsx

.contact-section-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  width: 80%;

  .section-title {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 2rem;
    text-align: left;
  }

  .accordion {
    border-top: 1px solid #d9e2ec;
  }

  .accordion-item {
    border-bottom: 1px solid #d9e2ec;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: space-between;

    &:hover {
      background-color: #f1f5f9;
    }

    h3 {
      font-size: 1.1rem;
      color: #030538;
      margin: 5px;
    }

    .icon {
      width: 12px;
      height: 12px;
      background-color: #79A3DC;
      border-radius: 50%;
      margin-right: 10px;
    }

    .dropdown-icon {
      margin-left: auto;
      transition: transform 0.3s ease;
    }

    .rotate {
      transform: rotate(180deg);
    }
    
  }

  .accordion-content {
    padding: 1rem;
    background-color: #f9fbff;

    .icon {
      margin-right: 10px;
    }

    p {
      margin: 0;
      color: #333;
      font-size: 1rem;
      margin: 0 1rem 1rem 2rem;
      display: flex;
      align-items: center;
    }
  }
}


@media (max-width: 982px) {
  .contact-section-container {
      max-width: none;
      margin: 0 auto;
      padding: 1rem;
      width: 90%;
  }
}