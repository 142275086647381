// OurCapability.scss

.our-capability-section {
    margin: 0;
    padding: 2rem 4rem;
    padding-bottom: 4rem;
  
    .our-capability {
      .our-capability-title {
        text-align: center;
  
        h1 {
          font-size: 2.5rem;
          margin-top: 0;
          margin-bottom: 3rem;
        }
      }
  
      .our-capability-div {
        //display: flex;
        justify-content: center;
        overflow-x: auto;  // Enable horizontal scrolling for mobile view
        padding-bottom: 1rem;
  
        .capability-table {
          width: 100%;
          max-width: 1000px;
          border-collapse: collapse;
          text-align: left;
          font-size: 1rem;
          border-bottom: 6px solid;
          border-right: 6px solid;
  
          th,
          td {
            padding: 1rem;
            border: 3px solid #000;
          }
  
          thead {
            //background-color: #f7f7f7;
  
            th {
              font-weight: bold;
              text-align: center;
            }
          }
        }
      }
    }
  }
 
  @media (min-width: 1199px) {
    .our-capability-section {
      .our-capability {
        max-width: 1000px;
        margin: auto;
      }
    }
  }

  @media (max-width: 990px) {
    .our-capability-section {
        padding: 2rem 1.5rem;
        padding-bottom: 4rem;
    }
  }
  