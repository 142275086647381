// WorldwideSec.scss

.worldwide-sec {
    //background-color: #e5f7ff; /* Light Blue background color */
    background-color: ghostwhite;
    position: relative;
    padding: 60px 0;
    overflow: visible; 
    

    .w-top-pattern {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 265px;
        width: 25%;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .container {
        //--bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: calc(var(--bs-gutter-x)* .5);
        margin-right: auto;
        margin-left: auto;
        max-width: 1200px;
    }

    .center-cont {
        max-width: 466px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px; 
        padding: 10px;
        
        h2 {
            color: #000;
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 40px;
            line-height: 1;
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            position: relative;
            text-transform: capitalize;
        }

        p {
            font-size: 18px; /* Adjust font size */
            color: #666; /* Gray text */
        }
    }

    .ship-map-area {
        position: relative;
        text-align: center; /* Ensure map is centered */

        .main-img {
            width: 70%;
            height: auto;
        }

        .shiping-area {
            /*display: flex;
            align-items: center;
            position: absolute;
            bottom: 80px;
            right: 50%;
            transform: translateX(50%);
            background: #fff;
            padding: 10px 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);*/
            display: inline-flex;
            align-items: center;
            position: absolute;
            bottom: 80px;
            right: 50%;
            transform: translateX(50%);

            .ship-img {
                width: 85px;
                height: 85px;
                flex-shrink: 0;
            }

            .ship-text {
                width: calc(100% - 85px);
                padding-left: 15px;
                
                h3 {
                    font-size: 25px;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    color: #2d8ccb;
                    padding: 5px;
                    margin: 0px;
                    //float: left;
                }

                p {
                    font-size: 18px;
                    padding: 5px;
                    margin: 0px;
                    float: left;
                }
            }
        }

        .vertical-text-right {
            color: #daf0ff;
            position: absolute;
            top: 50%;
            right: -140px;
            transform: translateY(-50%);
            font-size: 64px;
            font-style: normal;
            line-height: 1.6;
            white-space: nowrap;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
        }
    }

    .contact-us {
        //background-color: #2d8ccb;
        background-color: #79A3DC;
        color: #fff;
        padding: 20px;
        text-align: center;
        width: 65%;
        border-radius: 8px;
        margin: auto;
        margin-bottom: -8rem; 
        position: relative;
        z-index: 3;

        // Optional: Box shadow for better visibility
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        p {
            margin-left: 20%;
            margin-right: 20%;
        }
  
        h2 {
            margin-bottom: 10px;
            font-size: 35px;
            margin-top: 1rem;
        }
  
        button {
            background-color: #fff;
            color: #1E3A8A;
            padding: 10px 20px;
            border-radius: 50px;
            cursor: pointer;
            margin-top: 15px;
            border: 2px solid #fff;
            margin-bottom: 2rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            padding: 16px 30px;
            min-width: 163px;
            text-align: center;
            overflow: hidden;
            position: relative;
  
            &:hover {
                //background-color: #79A3DC;
                background-color: #2d8ccb;
                color: #fff;
                transition: all .3s ease-in-out;
                text-transform: capitalize;
            }
        }
    }

    .contact-us::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url('../../images/backgroundImg/blue-bg-area.png') 100% / cover no-repeat;
        //background: url('../../images/backgroundImg/common-background-White-1.png') 100% / cover no-repeat;
        //opacity: 0.07;
        z-index: -1;
        border-radius: 12px;
    }

}

@media (max-width: 1439px) {
    .ship-map-area {
        .vertical-text-right {
            font-size: 55px;
            right: -63px;
        }
    }
}

@media (max-width: 1199px) {
    .worldwide-sec {
        .center-cont h2 {
            font-size: 32px;
        }
        .ship-map-area {
            .vertical-text-right, .vertical-txt {
                display: none;
            }
        }
        .contact-us {
            margin-bottom: -7rem;
        }
    }
}

@media (max-width: 991px) {
    .worldwide-sec {
        .cmn-gap {
            padding: 70px 0;
        }
        .center-cont h2 {
            font-size: 30px;
        }
        .ship-map-area {
            text-align: center;
            .main-img {
                width: 100%;
            }
            .vertical-text-right, .vertical-txt {
                display: none;
            }
            .shiping-area {
                display: inline-flex;
                position: relative;
                bottom: auto;
                right: auto;
                transform: inherit;
                text-align: left;
                margin: 10px;
                margin-bottom: 5rem;
            }
        }
        .contact-us {
            p {
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    }
}

@media (max-width: 795px) {
    .worldwide-sec {
        .contact-us {
            width: 80%;
            margin-top: -1rem;
        }
    }
}

@media (max-width: 767px) {
    .worldwide-sec {
        .center-cont h2 {
            font-size: 28px;
        }
        .ship-img {
            width: 50px;
            height: 50px;
        }
        .ship-map-area {
            .shiping-area {
                margin-bottom: 3rem;
                .ship-text h3, .ship-text p {
                    font-size: 18px;
                    padding: 3px;
                    margin: 0px;
                }
            }
        }
    }
}

@media (max-width: 515px) {
    .worldwide-sec {
        .contact-us {
            p {
                margin-left: 0px;
                margin-right: 0rem;
            }
        }
    }
}

@media (max-width: 479px) {
    .worldwide-sec {
        .cmn-gap {
            padding: 50px 0;
        }
        .center-cont h2 {
            font-size: 24px;
        }
        .ship-map-area {
            .shiping-area {
                .ship-text h3 {
                    font-size: 15px;
                } 
                .ship-text p {
                    font-size: 15px;
                }
            }
        }
    }
}