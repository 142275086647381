// AboutUs.jsx

/* AboutUs.scss */

/* General Styles */
// .cmn-gap {
//     padding: 50px 0;
//   }

.AboutUs {
  .about {
    .who-we-are-col {
      .who-we-are-text-area {
        text-align: justify;
        text-justify: inter-word;
        h2 {
          font-size: 2.5rem;
        }
        p {
          font-size: 1.1rem;
        }
      }
    }
  }
}