// SEMIStandardSpecifications.scss

.SEMI-standard-specifications-section {
    margin: 0;
    padding: 2rem 4rem;
    padding-bottom: 4rem;
  
    .SEMI-standard-specifications {
      .SEMI-standard-specifications-title {
        text-align: center;
  
        h1 {
          font-size: 2.5rem;
          margin-top: 0;
          margin-bottom: 3rem;
        }
      }
  
      .SEMI-standard-specifications-div {
        //display: flex;
        justify-content: center;
        overflow-x: auto;  // Enable horizontal scrolling for mobile view
        padding-bottom: 1rem;
  
        .SEMI-standard-specifications-table {
          width: 100%;
          max-width: 1000px;
          border-collapse: collapse;
          text-align: left;
          font-size: 1rem;
          border-bottom: 6px solid;
          border-right: 6px solid;
  
          th,
          td {
            padding: 1rem;
            border: 3px solid #000;
          }
  
          thead {
            //background-color: #f7f7f7;
  
            th {
              font-weight: bold;
              text-align: center;
            }
          }
        }
      }
      
      p {
            font-size: 1.2rem;
            margin: auto;
            margin-bottom: 1rem;
            padding: 0.5rem;
      }
    }
  }
 
  @media (min-width: 1199px) {
    .SEMI-standard-specifications-section {
      .SEMI-standard-specifications {
        max-width: 1000px;
        margin: auto;
      }
    }
  }

  @media (max-width: 990px) {
    .SEMI-standard-specifications-section {
        padding: 2rem 1.5rem;
        padding-bottom: 4rem;
    }
  }