// Earthcss.scss

.main-page-2 {
    .earth-css {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; // Ensure full viewport height
        background-color: #000; // Optional background color for contrast
    }

    @media (max-width: 768px) {
        .earth-css {
            transform: scale(0.5); // Adjust scale for mobile view (50% smaller)
        }
    }
}
