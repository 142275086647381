// YourWaferPartner.scss
:root {
    --width: 11rem; // Declaring --width
    --height: 5.8rem; // Declaring --height
}

.your-wafer-partner-section {
    width: 100%;
    //display: flex;
    //text-align: left;
    align-items: center;
    justify-content: center;
    position: relative;

    .your-wafer-partner {
        margin: auto;
        padding: 2rem 7vw;
        padding-bottom: 4rem;
        z-index: 2; // image is above background
        position: relative; // Add relative positioning to manage z-index properly

        h1 {
            font-size: 2.5rem;
        }

        p {
            font-size: 1.1rem;

            a {
                text-decoration: none;
                color: #000;
            }
        }

        .cmn-partner {
            width: fit-content;
            display: flex;
            margin: auto;

            .cmn-card-pic {
                position: relative;
                z-index: 2; // image is above background

                img {
                    max-width: 10rem;
                    max-height: 5.8rem;
                    margin: 1rem;
                }
            }
        }

        .your-wafer-partner-text-2 {
            display: none;
        }
    }
}

.products {
    .your-wafer-partner-section {
        background-color: #2d8ccb;
        color: #fff;

        .your-wafer-partner {
            overflow: hidden;

            p {
                display: none;
            }

            .cmn-partner {
                display: flex;
                min-width: calc(var(--width) * var(--quantity));
                min-width: 100%;
                //min-width: 100%;
                height: var(--height);
                position: relative;
                overflow: hidden;
                margin-top: 3rem;
                margin: 2.5rem auto;

                &:hover .cmn-card-pic {
                    animation-play-state: paused !important;
                }

                .cmn-card-pic {
                    width: var(--width);
                    height: var(--height);

                    position: absolute;
                    right: 100%;

                    display: flex;
                    justify-content: center; // Horizontally center
                    align-items: center; // Vertically center
                    text-align: center;
                    margin: auto;

                    background-color: #000;

                    animation: autoRun 10s linear infinite; // Infinite scroll animation (adjust time as needed)
                    animation-delay: calc((10s / var(--quantity)) * (var(--position) - 1));

                    img {
                        background-color: #fff;
                        margin: 0px;
                    }
                }

                .cmn-card-pic-2,
                .cmn-card-pic-6 {
                    background-color: #fff;
                }

                // .cmn-card-pic-7 {
                //     //display: none;
                // }

            }

            .your-wafer-partner-text-2 {
                display: block;
                position: relative;

                p {
                    display: block;
                }
            }
        }

        .your-wafer-partner-container {
            background-image: url('../../images/backgroundImg/common-background-White.png');
            background-image: url('../../images/backgroundImg/light-gray-texture-background_149326-9672.png');
            opacity: 0.3;
            transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1; // Ensure background is behind content
        }
    }
}

// Keyframes for infinite scroll
@keyframes autoRun {
    from {
        right: 100%;
    }

    to {
        right: calc(var(--width) * -1);
    }
}


.home {
    .your-wafer-partner-section {
        //display: flex;
        justify-content: center;
        align-items: center;

        .your-wafer-partner {
            display: flex;
            justify-content: space-between;
            //width: 100%;
            max-width: 1200px;
            padding: 4rem 7vw;

            .your-wafer-partner-text {
                text-align: right;
                width: 50%;
                margin: auto 2rem;

                p {
                    a {
                        text-decoration: none;
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }

            .cmn-partner {
                display: grid;
                //grid-template-rows: repeat(3); // 3 rows
                gap: 20px; // space between logos
                width: 50%;
                align-items: center;
                max-width: 1000px;
                padding: 1rem;

                .cmn-card-pic {
                    background-color: #fff;
                    //background-color: #000;
                    border-radius: 10px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 4px 8px rgb(0 0 0 / 37%);
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 15vw;
                    width: 15vw;

                    // Add transition for smooth resizing
                    transition: all 0.3s ease; // Adjust duration as needed (0.3s is default)

                    img {
                        max-width: 100%;
                        max-height: 100%; // Adjust to fit within the design
                        object-fit: contain;
                    }

                    // Hide the partner card with the specific class
                    &.hidden-partner {
                        display: none;
                    }
                }

                // Large logo on the left
                .cmn-card-pic-1 {
                    grid-column: 1 / span 2; // Large logo spans the first column
                    grid-row: 1 / span 2;
                    align-self: flex-end;
                    background-color: #000;

                    // Hover effect on the large card that affects all
                    //&:hover ~ .cmn-card-pic {
                    &:hover {
                        height: calc(15vw + 20px);
                        width: calc(15vw + 20px);
                    }
                }

                // Remaining logos on the right
                .cmn-card-pic-2,
                .cmn-card-pic-3,
                .cmn-card-pic-4,
                .cmn-card-pic-5,
                .cmn-card-pic-6,
                .cmn-card-pic-7,
                .cmn-card-pic-8 {
                    height: calc(15vw / 2);
                    width: calc(15vw / 2);

                    &:hover {
                        height: calc(15vw / 2 + 20px);
                        width: calc(15vw / 2 + 20px);
                    }
                }

                .cmn-card-pic-2 {
                    grid-column: 3;
                    grid-row: 2;
                }

                .cmn-card-pic-3 {
                    grid-column: 2;
                    grid-row: 3;
                }

                .cmn-card-pic-4 {
                    grid-column: 3;
                    grid-row: 3;
                    background-color: #000;
                }

                .cmn-card-pic-5 {
                    grid-column: 4;
                    grid-row: 2;
                    background-color: #000;
                }

                .cmn-card-pic-8 {
                    grid-column: 4;
                    grid-row: 3;
                }
            }
        }
    }
}

@media (min-width: 1900px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                .cmn-partner {
                    .cmn-card-pic {
                        height: 200px;
                        width: 200px;
                    }

                    .cmn-card-pic-1 {
                        &:hover {
                            height: calc(200px + 20px);
                            width: calc(200px + 20px);
                        }
                    }

                    // Remaining logos on the right
                    .cmn-card-pic-2,
                    .cmn-card-pic-3,
                    .cmn-card-pic-4,
                    .cmn-card-pic-5,
                    .cmn-card-pic-6,
                    .cmn-card-pic-7,
                    .cmn-card-pic-8 {
                        height: calc(200px / 2);
                        width: calc(200px / 2);

                        &:hover {
                            height: calc(200px / 2 + 20px);
                            width: calc(200px / 2 + 20px);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1900px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                .cmn-partner {
                    .cmn-card-pic {
                        height: 13vw;
                        width: 13vw;
                    }

                    .cmn-card-pic-1 {
                        &:hover {
                            height: calc(13vw + 20px);
                            width: calc(13vw + 20px);
                        }
                    }

                    // Remaining logos on the right
                    .cmn-card-pic-2,
                    .cmn-card-pic-3,
                    .cmn-card-pic-4,
                    .cmn-card-pic-5,
                    .cmn-card-pic-6,
                    .cmn-card-pic-7,
                    .cmn-card-pic-8 {
                        height: calc(13vw / 2);
                        width: calc(13vw / 2);

                        &:hover {
                            height: calc(13vw / 2 + 20px);
                            width: calc(13vw / 2 + 20px);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                .cmn-partner {
                    .cmn-card-pic {
                        height: 15vw;
                        width: 15vw;
                    }

                    .cmn-card-pic-1 {
                        &:hover {
                            height: calc(15vw + 20px);
                            width: calc(15vw + 20px);
                        }
                    }

                    // Remaining logos on the right
                    .cmn-card-pic-2,
                    .cmn-card-pic-3,
                    .cmn-card-pic-4,
                    .cmn-card-pic-5,
                    .cmn-card-pic-6,
                    .cmn-card-pic-7,
                    .cmn-card-pic-8 {
                        height: calc(15vw / 2);
                        width: calc(15vw / 2);

                        &:hover {
                            height: calc(15vw / 2 + 20px);
                            width: calc(15vw / 2 + 20px);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1255px) {
    .products {
        .your-wafer-partner-section {
            .your-wafer-partner {
                .cmn-partner {
                    min-width: calc(var(--width) * var(--quantity));
                    margin-left: -6rem;
                }
            }
        }
    }
}

@media (min-width: 1199px) {
    .your-wafer-partner-section {
        .your-wafer-partner {
            max-width: 1200px;
        }
    }
}

@media (max-width: 1079px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                padding: 4rem 4vw;

                p {
                    font-size: 1rem;
                }

                .cmn-partner {
                    gap: 15px
                }
            }
        }
    }
}

@media (max-width: 958px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                .your-wafer-partner-text {
                    margin-right: 1rem;

                    h1 {
                        font-size: 2.3rem;
                        margin: 0px;
                    }

                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 848px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                display: block;

                .your-wafer-partner-text {
                    text-align: left;
                    text-align: center;
                    margin: auto 2rem;
                    width: auto;

                    p {
                        display: none;
                    }
                }

                .cmn-partner {
                    padding: 2rem;
                    padding-top: 3rem;
                    gap: 13px;
                    width: fit-content;

                    .cmn-card-pic {
                        height: 22vw;
                        width: 22vw;
                        padding: 13px;
                    }

                    .cmn-card-pic-1 {
                        &:hover {
                            height: calc(22vw + 20px);
                            width: calc(22vw + 20px);
                        }
                    }

                    // Remaining logos on the right
                    .cmn-card-pic-2,
                    .cmn-card-pic-3,
                    .cmn-card-pic-4,
                    .cmn-card-pic-5,
                    .cmn-card-pic-6,
                    .cmn-card-pic-7,
                    .cmn-card-pic-8 {
                        height: calc(200px / 2);
                        width: calc(200px / 2);
                        height: calc(22vw / 2);
                        width: calc(22vw / 2);

                        &:hover {
                            height: calc(22vw / 2 + 20px);
                            width: calc(22vw / 2 + 20px);
                        }
                    }
                }

                .your-wafer-partner-text-2 {
                    display: block;
                    text-align: left;
                    text-align: center;
                    margin: auto 2rem;

                    p {
                        a {
                            text-decoration: none;
                            color: #000;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 509px) {
    .home {
        .your-wafer-partner-section {
            .your-wafer-partner {
                .your-wafer-partner-text {
                    margin: auto 1rem;
                    text-align: left;

                    h1 {
                        font-size: 35px;
                    }
                }

                .cmn-partner {
                    padding: 2rem 0px;
                    padding-top: 3rem;

                    .cmn-card-pic {
                        height: 30vw;
                        width: 30vw;
                    }

                    .cmn-card-pic-1 {
                        &:hover {
                            height: calc(24vw + 20px);
                            width: calc(24vw + 20px);
                        }
                    }

                    // Remaining logos on the right
                    .cmn-card-pic-2,
                    .cmn-card-pic-3,
                    .cmn-card-pic-4,
                    .cmn-card-pic-5,
                    .cmn-card-pic-6,
                    .cmn-card-pic-7,
                    .cmn-card-pic-8 {
                        height: calc(200px / 2);
                        width: calc(200px / 2);
                        height: calc(30vw / 2);
                        width: calc(30vw / 2);

                        &:hover {
                            height: calc(24vw / 2 + 20px);
                            width: calc(24vw / 2 + 20px);
                        }
                    }
                }

                .your-wafer-partner-text-2 {
                    text-align: left;
                    margin: auto 1rem;
                }
            }
        }
    }
}