// MajorApplicationsOfSiliconWafers.scss

.major-applications-of-silicon-wafers-section {
    margin: 0px;
    padding: 5rem 4rem;

    .major-applications-of-silicon-wafers {
        .major-applications-of-silicon-wafers-title {
            text-align: center;

            h1 {
                font-size: 2.5rem;
                margin-top: 0rem;
                margin-bottom: 3rem;
            }
        }

        .major-applications-of-silicon-wafers-div {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
            .block {
                border: #000 solid 2px;
                border-bottom: 5px solid;
                border-right: 5px solid;
                padding: 15px;

                h1 {
                    margin-top: 0px;
                }
            }
        }
    }
}

@media (min-width: 1199px) {
    .major-applications-of-silicon-wafers-section {
        .major-applications-of-silicon-wafers {
            max-width: 1000px;
            margin: auto;
        }
    }
}

@media (max-width: 801px) {
    .major-applications-of-silicon-wafers-section {
        .major-applications-of-silicon-wafers {
            .major-applications-of-silicon-wafers-div {
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;
            }
        }
    }
}

@media (max-width: 601px) {
    .major-applications-of-silicon-wafers-section {
        padding: 4rem 1.5rem;
        .major-applications-of-silicon-wafers {
            .major-applications-of-silicon-wafers-div {
                grid-template-columns: repeat(1, 1fr);
                gap: 1rem;
            }
        }
    }
}