//WhatWeMake.scss
// Defining a mixin for reusability
@mixin arial-bold {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.what-we-make {
  position: relative;

  &.cmn-gap {
    padding: 80px 0 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 418px;
    z-index: 1;
  }

  &::before {
    background: #137cc2;
  }

  &::after {
    background: linear-gradient(118deg, rgba(0, 85, 142, 0) 33.03%, #02263e 91.75%);
    z-index: 2;
  }

  .what-we-make-container {
    margin: 0px auto;
    //margin-top: -5rem;
    max-width: 1164px;
    margin-top: -2rem;
  }

  .top-circle {
    position: absolute;
    top: -80px;
    left: -80px;
    z-index: 3;
    width: 44%;
    max-width: 331px;
  }

  .bottom-circle {
    position: absolute;
    top: calc(100% + 80px);
    right: 0;
    max-width: 216px;
    width: 29%;
  }

  .dot-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 12%;
    max-width: 125px;
  }

  .top-pattern {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    max-width: 253px;
    width: 15%;

    .img-top-pattern {
      max-width: 100%;
      height: auto;
    }
  }

  .top-vector {
    position: absolute;
    top: 380px;
    left: 0;
    z-index: revert-layer;
    max-width: 320px;
    width: 35%;
  }

  .center-cont {
    max-width: 770px;
    margin: 0 auto 40px;
    color: #fff;
    z-index: 3;
    position: relative;

    h2 {
      font-weight: 400;
      font-size: 40px;
      line-height: 1;
      font-family: var(--arialbold);
      position: relative;
      text-transform: capitalize;
    }

    h2::before {
      content: "";
      display: inline-block;
      width: 127px;
      height: 1px;
      display: inline-block;
      background: linear-gradient(-90deg, #2d8ccb 0, rgba(255, 255, 255, 0) 62.2%);
      opacity: .7;
      margin-right: 18px;
      vertical-align: middle;
    }

    h2::after {
      content: "";
      display: inline-block;
      width: 127px;
      height: 1px;
      display: inline-block;
      background: linear-gradient(90deg, #2d8ccb 0, rgba(255, 255, 255, 0) 62.2%);
      opacity: .7;
      margin-left: 18px;
      vertical-align: middle;
    }

    p:last-child {
      margin-bottom: 0;
      margin: 0px 10px;
      font-size: 14.5;
    }
  }

  .cmn-slider-wrap {
    position: relative;
  }

  .swiper-horizontal {
    margin-bottom: 15px;
    position: relative;
    z-index: 3;
    width: 80%;
  }

  h2 {
    color: #fff;

    &::after,
    &::before {
      background: linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0) 62.2%);
    }
  }

  button {
    background-color: #1e3a8a;
    color: #fff;
    padding: 10px 20px;
    //border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 16px 30px;
    min-width: 163px;
    //border-radius: 26px;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: 2px solid #1e3a8a;

    i {
      margin-left: 10px;
      color: #fff;
    }

    &:hover {
        background-color: #fff;
        color: #1e3a8a;
        //border: #fff;
        border: 2px solid #1e3a8a;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: all .3s ease-in-out;
        text-transform: capitalize;
    }
  }
}

.what-we-make {
  position: relative;
  overflow: hidden;
  padding: 80px 0;

  .swiper-container {
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    //width: 100% !important;
    transform: translate3d(-1164px, 0px, 0px);
    //margin-left: 4rem;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    margin-right: 0px;
    //width: 270px !important;
  }

  .swiper-button-prev {
    //display: none;
    left: calc(50% - 50px);
    margin-top: 14.1rem;
  }

  .swiper-button-next {
    //display: none;
    right: calc(50% - 50px);
    margin-top: 14.1rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-shadow: 0 10px 13px 0 rgba(45, 140, 203, .12);
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -ms-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px #00000060;
    color: #000;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    color: #2d8ccb;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 22px;
    /* Adjust the size of the arrow icon */
  }

  .cmn-card {
    flex-shrink: 0;
    width: 100%;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 8px 10px 0 rgba(45, 140, 203, 0.16);
    min-height: 380px;
    height: 100%;
    padding: 30px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 180px; // Adjust based on design
    margin-bottom: 5rem;
    //margin-bottom: 20px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
    }

    /*&::before {
      top: -1px;
      left: -1px;
      background: url('../../images/Style/card-svg-top.svg') center/contain no-repeat;
      height: 46px;
      width: 157px;
      opacity: 0.8;
    }

    &::after {
      bottom: -1px;
      right: -3px;
      background: url('../../images/Style/card-svg-bottom.svg') center/contain no-repeat;
      height: 46px;
      width: 157px;
      opacity: 0.5;
    }*/

    .cmn-card-pic {
      height: 150px;
      //width: 100%;
      width: 90%;
      //margin: 0 auto 15px 0;
      margin: auto;
      margin-bottom: 15px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      font-size: 20px;
      position: relative;
      padding: 10px;
      margin-bottom: 14px;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #2d8ccb;
        color: #02263e;
        width: 50%;
        height: 1px;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 14px;
      color: #53778f;
    }

    .lean-more {
      color: #2d8ccb;
      font-weight: 600;
      font-size: 14px;

      i {
        transform: rotate(45deg);
        font-size: 15px;
        margin-left: 6px;
      }
    }
  }

  .btn-center {
    text-align: center;
    margin-bottom: 2rem;

    .cmn-btn {
      background-color: #1E3A8A;
      color: #fff;
      border-radius: 50px;
      cursor: pointer;
      padding: 12px 30px;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      text-align: center;
      text-decoration: none;
      transition: all 0.4s ease-in-out;
      border: 2px solid #1E3A8A;
      margin-bottom: 2rem;
      position: relative;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -ms-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: all .3s ease-in-out;
      text-transform: capitalize;
      border: 2px solid 1E3A8A;


      .cmn-btn::before {
        content: "";
        background: #fff;
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: all .3s ease-in-out;
      }

      &:hover {
        background-color: #fff;
        color: #1E3A8A;
        //border: #fff;
      }

      i {
        margin-left: 10px;
      }
    }

    i {
      display: inline-block;
      margin-left: 5px;
      transition: all 0.4s ease-in-out;

      img {
        width: 6px;
      }
    }

    span {
      position: relative;
    }
  }
}

@media (max-width: 1199px) {
  .what-we-make .center-cont h2 {
    font-size: 32px;
  }
}

@media (max-width: 1162px) {
  .what-we-make {
    .cmn-card {
      margin-bottom: 80px;
    }

    .swiper-horizontal {
      width: 70%;
    }

    .swiper-button-prev {
      //display: none;
      //left: 10px;
      //margin-top: auto;
      color: #000;
    }

    .swiper-button-next {
      //display: none;
      //right: 10px;
      //margin-top: auto;
      color: #000;
    }

    .swiper-button-prev,
    .swiper-button-next {
      //display: flex;
      color: #000;
      //font-size: 10px;
    }
  }
}

// @media (max-width: 1170px) {
//   .swiper-wrapper {
//     //width: 100% !important;
//   }
//   .swiper-slide {
//     //width: 300px !important;
//   }
// }
@media (max-width: 991px) {
  .what-we-make {
    .center-cont h2 {
      font-size: 30px;
    }

    .swiper-horizontal {
      width: 70%;
    }
  }
}

@media (max-width: 989px) {
  .what-we-make {
    .swiper-horizontal {
      width: 60%;
    }
  }
}

@media (max-width: 767px) {
  .what-we-make {
    .center-cont h2 {
      font-size: 28px;
    }

    .cmn-card {
      max-width: 200px;
    }

    .swiper-horizontal {
      width: 100%;
    }
  }

  .swiper-horizontal {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .what-we-make .center-cont h2 {
    font-size: 30px;
  }

  .what-we-make .center-cont h2::before {
    display: none;
  }

  .what-we-make .center-cont h2::after {
    display: none;
  }
}

@media (max-width: 455px) {
  .swiper-slide {
    width: 100%; // This ensures only one card is shown on screens smaller than 455px wide.
  }
}

@media (min-width: 300px) and (max-width: 454px) {
  .swiper-slide {
    width: 50%;
  }
}

@media (max-width: 299px) {
  .swiper-slide {
    width: 100%;
  }
}