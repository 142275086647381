// HowWeDoIt.scss

.how-we-do-it-area {
    background-color: #79A3DC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .how-we-do-it-area-container {
        background-image: url('../../images/backgroundImg/common-background-White.png');
        opacity: 0.1;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .container {
        width: 90%;
        z-index: 2;
        margin: 2rem;
        width: 70%;
        max-width: 1000px;
        margin-bottom: 4rem;

        .how-we-txt {
            //text-align: justify;
            //text-justify: inter-word;

            h2 {
                font-size: 3rem;
                color: #fff;
                margin-bottom: 10px;
            }

            p {
                font-size: 1.2rem;
                color: #fff;
                margin-bottom: 20px;

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .how-we-do-it-area {
        .container {
            width: 80%;
            .how-we-txt {
                h2 {
                    font-size: 1.7rem;
                }
                p {
                    font-size: 1rem;
                }
            }
        }
    }
}