// SiliconWaferSolutions.scss

.SiliconWaferSolutions {
    background-color: ghostwhite;
    padding: 4rem 1rem;
    .container {
        margin: auto;
        width: 80%;
        color: #333;
        .SiliconWaferSolutions_container {
            h1 {
                margin: 0px;
                font-size: 3rem;
                margin-left: 10px;
            }
            ul {
                font-size: 1.2rem;
                li {
                    padding: 0.5rem;
                }
            }

        }
    }
}