// ContactUsForm.scss

.ContactUsForm {
    width: 100%;

    .ContactUsForm-section {
        width: 60%;
        display: flex;
        margin: 5rem auto;
        max-width: 1200px;
    }
}

/* Media Queries for Mobile */
@media (max-width: 1366px) {
    .ContactUsForm {
        .ContactUsForm-section {
            width: 70%;
        }
    }
}

@media (max-width: 1170px) {
    .ContactUsForm {
        .ContactUsForm-section {
            width: 80%;
        }
    }
}

@media (max-width: 1075px) {
    .ContactUsForm {
        .ContactUsForm-section {
            width: 90%;
        }
    }
}

@media (max-width: 982px) {
    .ContactUsForm {
        .ContactUsForm-section { 
            display: block;
            width: 90%;
        }
    }
}