// main1.scss

.main1 {
    position: relative; // To make sure child elements are positioned relative to this container
    height: 100vh; // Makes the main container take up the full viewport height
    overflow: hidden; // Prevents scrolling if content overflows
  
    h1 {
      position: relative; // Keeps the heading on top of the background
      z-index: 2; // Ensures it stays above the iframe background
      color: white;
      text-align: center;
      padding-top: 20px; // Adjust the padding to center as needed
    }
  
    .sketchfab-embed-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; // Sets it below the content in .main1
      iframe {
        width: 100%;
        height: 100%;
        border: none; // Removes iframe border
      }
    }
  }
  