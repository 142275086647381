.footer {
    background-color: #030538;
    //background-color: #02263e;
    padding: 40px;
    color: white;
    position: relative;
    //z-index: 1;
    overflow: visible; // Allow content to overflow out of the footer
    text-align: center;

    .footer-mx {
        max-width: 1200px;
        margin: auto;
        margin-top: 7rem;
    }
  
    .quick-links ul,
    .products ul {
        list-style-type: disc; /* Adds bullets */
        padding-left: 20px; /* Adjust padding for the bullets */
    }

    .quick-links li,
    .products li {
        margin-bottom: 5px; /* Space between items */
    }

    .quick-links a,
    .products a {
        color: #fff;
        text-decoration: none;
    }

    .quick-links a:hover,
    .products a:hover {
        color: #79A3DC;
    }


    .footer-content {
        display: flex;
        justify-content: space-between;
        width: 85%;
        margin: auto;
        margin-top: 3rem;
  
        .footer-logo img {
            height: 200px;
            margin-top: 2rem;
        }
  
        .quick-links,
        .products {
            display: flex;
            flex-direction: column;
            text-align: left;
  
            h3 {
                margin-bottom: 5px;
                font-size: 25px;
            }
  
            a {
                color: #fff;
                text-decoration: none;
                margin-bottom: 5px;
                margin-left: -10px;
  
                &:before {
                    content: "";
                    display: inline-block;
                    width: 10px; // Adjust to fit the spacing
                }
  
                &:hover {
                    color: #a9c1f4;
                }
            }
        }
  
        .contact-info {
            text-align: left;
  
            h3 {
                margin-bottom: 20px;
                font-size: 25px;
            }
  
            p {
                margin-bottom: 5px;
                display: flex;
                align-items: center;

                i {
                    margin-right: 10px;
                }
  
                &:before {
                    font-family: FontAwesome;
                    margin-right: 10px; /* Space between icon and text */
                    color: #fff; /* Icon color */
                    font-size: 18px; /* Adjust icon size */
                }
            }
        }
    }
  
    .footer-bottom {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        padding-top: 20px;
        border-top: 1px solid #ddd;
        width: 100%;
        text-align: left;
  
        p {
            margin: 0;
            width: 100%;
            a {
                text-decoration: none;
                color: #fff;
                &:hover {
                    color: #a9c1f4;
                }
            }
        }
  
        .payment-icons {
            display: flex;
            gap: 10px;
  
            img {
                height: 25px;
            }
        }
    }
  
    @media (max-width: 1199px) {
        .footer-content {
            //flex-direction: column;
            display: block;
            align-items: center;
            text-align: center;
            margin-top: 1rem;
  
            .quick-links,
            .products,
            .contact-info {
                margin-bottom: 20px;
                align-self: flex-start;
                display: inline-flex;
                margin: auto 20px;
            }
  
            .footer-logo img {
                height: 150px;
                margin-top: 1rem;
            }
        }
    }

    @media (max-width: 992px) {
        .footer-content {
            width: auto;
            .quick-links,
            .products,
            .contact-info {
                margin: auto 10px;
            }
        }
    }

    @media (max-width: 795px) {
        .footer-content {
            .quick-links,
            .products,
            .contact-info {
                width: 40%;
                float: left;
            }
            .products {
                width: 40vw;
                float: right;
            }
            .contact-info {
                display: block;
                width: 100%;
            }
        }
        // .contact-us {
        //     width: 90%;
        //     margin-top: -6rem; // Adjusted for mobile view
        // }
    }
  
    @media (max-width: 515px) {
        .footer-bottom {
            display: block;
            align-items: center;
            text-align: center;
            p {
                margin-bottom: 20px;
                width: 100%;
            }
            .payment-icons {
                display: block;
                margin: auto;
                align-items: center;
  
                img {
                    margin-left: 10px;
                }
            }
        }
        .footer-content {
            flex-direction: column;
            display: flex;
            .quick-links,
            .products,
            .contact-info {
                width: auto;
                float: left;
            }
        }
    }
}