// WorkingWaperSec.scss
.working-waper-sec {
    // min-height: 466px;
    // background: linear-gradient(135deg, #1b3a57, #276078); // Adjust gradient colors to match the background
    //padding: 60px 0;
    min-height: 466px;
    background-color: #79A3DC;
    margin-top: -88px;
    overflow: hidden;
    
    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: calc(var(--bs-gutter-x)* .5);
        margin-right: auto;
        margin-left: auto;
        max-width: 90%;

        .working-waper-row {
            align-items: center;
        }

        .row {
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1* var(--bs-gutter-y));
            margin-right: calc(-.5* var(--bs-gutter-x));
            margin-left: calc(-.5* var(--bs-gutter-x));

            
        }
    }
    button {
        background-color: #fff;
        color: #1E3A8A;
        padding: 10px 20px;
        //border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 15px;
        border: 2px solid #fff;
        margin-bottom: 2rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        padding: 16px 30px;
        min-width: 163px;
        //border-radius: 26px;
        text-align: center;
        overflow: hidden;
        position: relative;

        i {
           margin-left: 10px;
           color: #fff;
        }

        &:hover {
            background-color: #79A3DC;
            color: #fff;
            //border: #fff;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -ms-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: all .3s ease-in-out;
            text-transform: capitalize;
        }
    }
}

.working-waper-img {
    position: relative;
    isolation: isolate;

    img {
        width: 100%;
        max-width: 542px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        z-index: 3;
        height: auto;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: -100px; // Adjust to fine-tune the position
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        max-width: 600px;
        max-height: 600px;
        border-radius: 50%;
        background-color: #fff;
        z-index: 1;
        opacity: 0.1;
    }
}

.working-waper-text {
    color: #fff;
    padding: 100px 0 0 30px;
    text-align: start;

    h2 {
        font-weight: 400;
        font-size: 40px;
        line-height: 1;
        font-family: 'Arial', sans-serif;
        font-weight: bold;
        position: relative;
        text-transform: capitalize;
        margin: 0 0 25px;
        padding: 0;
    }

    p {
        margin-bottom: 40px;
        color: #fff;
    }

    .cmn-btn {
        display: inline-flex;
        align-items: center;
        background-color: #2c7a7b;
        color: var(--white);
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;

        i {
            margin-left: 10px;
            display: inline-block;

            img {
                width: 10px;
                height: auto;
            }
        }
    }
}

@media (min-width: 1199px) {
    .working-waper-sec {
        .container {
            max-width: 1000px;
        }
    }
}

@media (max-width: 1199px) {
    .working-waper-sec {
        .container {
            max-width: 1000px;
        }
        .h2-title, h2 {
            font-size: 30px;
        }
        .cmn-btn {
            min-width: 130px;
            padding: 14px 30px;
        }
    }
}

@media (max-width: 992px) {
    .working-waper-sec {
        .container {
            max-width: 90%;
        }
    }
}

@media (max-width: 991px) {
    .working-waper-sec {
        margin-top: 0;
        min-height: auto;
        padding: 30px;
        .working-waper-img::before {
            bottom: 0;
        }
        .working-waper-text {
            padding: 40px 0;

            .h2-title, h2 {
                font-size: 30px;
                line-height: normal;
            }
        }
    }
}

@media (min-width: 768px) {
    .working-waper-sec {
        .container {
            //max-width: 720px;

            .col-md-6 {
                flex: 0 0 auto;
                width: 50%;
            }
            .h2-title, h2 {
                font-size: 28px;
            }
        }
    }
}

@media (max-width: 768px) {
    .working-waper-sec {
        .container {
            .col-md-6 {
                width: 100%;
            }
        }
    }
}

@media (min-width: 576px) {
    .working-waper-sec {
        .container {
            //max-width: 90%;

            .h2-title, h2 {
                font-size: 33px;
            }
        }
    }
}