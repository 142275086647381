// WafersSection.scss

.Silicon-Nitride-Wafers,
.Fused-Silica-Wafers {
    .who-we-are-sec {
        padding-bottom: 40px;

        .who-we-are-col {
            .who-we-are-text-area {
                h2 {
                    font-size: 3rem;
                }

                p {
                    font-size: 1.1rem;
                    text-align: justify;
                    text-justify: inter-word;
                }
            }
        }
    } 
}

@media (max-width: 801px){
    .Silicon-Nitride-Wafers,
    .Fused-Silica-Wafers {
        .who-we-are-sec {
            .who-we-are-col {
                .who-we-are-text-area {
                    h2 {
                        font-size: 2.5rem;
                    }    
                    p {
                        font-size: 1rem;
                    }
                }
            }
        } 
    }
}