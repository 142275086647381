//Topbar.scss

.topbar {
  .announcement {
    //background-color: #1E3A8A;
    //background-color: #02263e;
    //background-color: #030538;
    background-color: red;
    color: white;
    text-align: center;
    padding: 5px;
    font-size: 15px;
    //display: none;
    //font-weight: bold;
    overflow: hidden;
  }

  /* Thick red border */
  hr.new4 {
    border: 4px solid #030538;
    margin: 0px;
  }

  .main-menu {
    display: flex;
    justify-content: center;
    /* Centers the entire main menu */
    align-items: center;
    padding: 15px;
    //gap: 20px;
    /* Adds space between logo and nav */

    .logo {
      border-radius: 50%;
      height: 100px;
      //height: 50px;
      //margin-right: 1.5rem;
      margin-right: 3rem;
    }

    .nav-toggle {
      display: none;
      /* Hide toggle button on larger screens */
      font-size: 30px;
      background: none;
      border: none;
      cursor: pointer;
      color: #000;
      font-weight: bold;
    }

    nav {
      display: flex;
      gap: 40px;

      .close-btn {
        display: none;
        cursor: pointer;
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin-top: 1rem;
      }

      a {
        text-decoration: none;
        color: black;
        /* Default text color */
        font-weight: bold;
        position: relative;
        /* Necessary for the underline effect */
        transition: color 0.3s ease;
        padding: 8px;

        &:hover {
          color: #2563EB;
          /* Blue color on hover */
        }

        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          /* Smaller underline */
          background-color: #2563EB;
          bottom: -5px;
          /* Adjust as needed */
          left: 50%;
          transition: width 0.3s ease-out, left 0.3s ease-out;
        }

        &:hover::before {
          width: 100%;
          left: 0;
        }

        &.active::before {
          width: 100%;
          left: 0;
        }
      }
    }

    .sub-dropdown {
      position: relative;
      padding-top: 8px;
      padding-bottom: 8px;

      .dropdown-arrow{
        padding: 0px;
        float: inline-end;
        img{
          transform: rotate(90deg);
        }
      }
    
      .sub-popup {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        min-width: 200px;
        padding: 0px;
        display: none; // Initially hidden
        list-style-type: none; // Remove bullet points
        margin: 0px;
    
        .dropdown-item {

          a {
            text-decoration: none;
            font-weight: bold;
            position: relative;
            transition: color 0.3s ease;
            padding: 10px 15px;
            color: #333;
            display: block; // Makes the span behave like a block element
            border-bottom: solid 1px #00000024;
            
            &:hover {
              background-color: #79A3DC;
              color: #fff;
            }

            &::before {
              display: none;
            }

            &.active {
              background-color: #79A3DC;
              color: #fff;
            }
          }
        }
      }
    
      &:hover .sub-popup {
        display: block; // Show on hover
      }
    
      &.open .sub-popup {
        display: block; // Show when open
      }
    }
  }

  /* Responsive Design for Mobile */

  @media (max-width: 952px) {
    .main-menu nav {
      gap: 36px;
    }
  }
  
  @media (max-width: 924px) {
    .main-menu nav {
      gap: 18px;
    }
  }

  @media (max-width: 888px) {

    .topbar {
      .main-menu {
        gap: 27px;

        .logo {
          //border-radius: 50%;
          height: 80px;
          margin-left: 1vw;
          margin-bottom: 1vw;
        }

        nav {
          gap: 17px;
        }
      }
    }
  }

  @media (max-width: 820px) {
    .main-menu nav {
        gap: 15px;
    }
  }

  @media (max-width: 814px) {
    .main-menu nav {
      gap: 13px;
    }
  }

  @media (max-width: 789px) {
    .main-menu nav {
        gap: 9.1px;
    }
  }

  @media (max-width: 768px) {
    .main-menu {
      justify-content: space-between;

      .logo {
        align-self: flex-start;
        display: block;
        margin-right: auto;
        height: 120px;
      }

      .nav-toggle {
        display: block;
        /* Show toggle button on mobile */
        margin-right: 20px;
      }

      .sub-dropdown {
        .dropdown-arrow{
          padding: 0px;
          float: inline-end;
          padding-right: 10px;
        }
      }

      nav {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 70%;
        background-color: white;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        padding: 20px;
        z-index: 1000;
        overflow-y: auto;
        //max-height: 100vh;
        /* Ensures nav is above other content */

        &.open {
          transform: translateX(0);
          /* Show menu */
        }

        .close-btn {
          display: block;
          font-size: 24px;
          background: none;
          border: none;
          cursor: pointer;
          align-self: flex-end;
          //margin-right: 1rem;
        }

        a {
          text-align: left;
          /* Align text to the left */
        }

        // a.active::before {
        //   width: 55%;
        // }
      }

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        /* Slight shadow effect */
        z-index: 999;
        /* Below nav but above other content */
      }

      .sub-dropdown .sub-popup {
        display: block;
        position: static;
        background: none;
        box-shadow: none;
        margin-top: 15px;
      }

    }
  }
}