// WafersList.scss

.wafers-List-section {
    .wafers-list {
        .wafers-List-title {
            h1 {
                margin-bottom: 1rem;
            }
        }
        .container {
            .card-container {
                .card-article {
                    img {
                        border-radius: 1rem;
                    }
                    .card-data {
                        top: 13rem;
                        bottom: 0.6rem;
                        border-radius: 0.5rem;
                        .card-price {
                            background-color: #555a674d;
                            border-radius: 1rem;
                            margin: 0.5rem 0rem;
                            padding: 5px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1070px) {
    .Wafers-List-section {
        .Wafers-List {
            .container {
                .card-container {
                    .card-article {
                        .card-data {
                            top: 12.7rem;
                        }
                    }
                }
            }
        }
    } 
}