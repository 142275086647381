// ProductsListWhatWeMake.scss

.products-list-what-we-make-section , .wafers-List-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .products-list-what-we-make, .wafers-list {
    margin: 3rem auto;

    .products-list-what-we-make-title, .wafers-List-title {
      text-align: center;
      margin: auto;
      width: 70%;

      h1 {
        font-size: 2.5rem;
        //text-align: center;
        margin-bottom: 0px;
      }

      p {
        font-size: 1.1rem;
      }
    }
    
    .container {
      //display: grid;
      place-items: center;
      //margin-inline: 1.5rem;
      //padding-block: 5rem;
      padding-block: 2rem;
      width: fit-content;
      margin: auto;

      .card-container {
        display: grid;
        //row-gap: 3.5rem;
        gap: 3.5rem;

        .card-article {
          position: relative;
          overflow: hidden;

          width: 328px;
          height: 400px;

          &:hover .card-data {
            animation: open-data 1s forwards;
            opacity: 1;
            transition: opaccity .3s;
          }         

          &:hover .card-data {
            padding: 1.5rem 2rem;
          }
          
          &:hover .card-description,
          &:hover .learn-more {
            margin-top: 1.5rem;
            display: block;
            opacity: 1;
            visibility: visible; // Show on hover
            transition: opacity 5s ease-in-out, visibility 0s 5s;
            // animation: show-data 2s forwards;
            // // transition: display 5s ease-in-out, visibility 5s ease-in-out;
            // opacity: 1;
            // transition: opaccity .3s;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 1.5rem;
            object-fit: cover;
          }

          .card-data {
            //width: 240px;
            height: auto;
            //height: 10rem;
            width: auto;
            background-color: #fff;
            //padding: 1.5rem 2rem;
            padding: 1rem 2rem;
            box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15);
            border-radius: 1rem;
            position: absolute;

            bottom: 1rem;
            //top: 15rem;
            top: 15.5rem;

            // left: 0;
            // right: 0;
            left: 0.6rem;
            right: 0.6rem;

            overflow: hidden;
            margin-inline: auto;

            //opacity: 0;
            transition: opacity 5s, visibility 5s, transform 5s; // Smooth transition
          }

          .card-title {
            //font-size: large;
            font-weight: 500;
            color: #000;
            margin: auto;
          }

          .card-description {
            display: block;
            //font-size: small;
            margin-top: 1.5rem;
            display: none;
            visibility: hidden; // Initially hidden
            transition: opacity 5s, visibility 5s; // Smooth transition
          }

          .learn-more {
            text-decoration: none;
            //font-size: small;
            font-weight: 500;
            color: #2d8ccb;
            display: none;
            visibility: hidden; // Initially hidden
            transition: opacity 5s, visibility 5s; // Smooth transition

            &:hover {
              text-decoration: underline;
            }

            i {
              transform: rotate(45deg);
              font-size: 15px;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}

@keyframes open-data {
  100% {
    //top: auto;
    top: 0.6rem;
    bottom: 0.6rem;
  }
}

@media (min-width: 1199px) {
  //.products {
    .products-list-what-we-make-section, .wafers-List-section {
      .products-list-what-we-make, .wafers-list {
        max-width: 1200px;
      }
    }
  //}
}

@media screen and (min-width: 1070px) {
  //.products {
    .products-list-what-we-make-section, .wafers-List-section {
      .products-list-what-we-make, .wafers-list {
        .container {

          //height: 100vh;
          .card-container {
            grid-template-columns: repeat(3, 1fr);

            //column-gap: 1.5rem;
            .card-article {
              width: 280px;
              //height: 300px;
              height: 350px;
            }

            // .card-data {
            //   width: 180px;
            //   padding-inline: 2.5rem;
            // }
          }
        }
      }
    }
  //}
}

@media screen and (max-width: 1070px) {
  //.products {
    .products-list-what-we-make-section, .wafers-List-section {
      .products-list-what-we-make, .wafers-list {
        .container {
          .card-container {
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
            .card-article {
              width: 275px;
              height: 328px;
              &:hover .card-data {
                padding: 1.5rem 2rem;
              }
              .card-data {
                padding: 0.6rem 2rem;
                top: 15rem;
                transition: opacity 5s, visibility 5s;
              }
            }
          }
        }
      }
    }
  //}
}

@media screen and (max-width: 1052px) {
  //.products {
    .products-list-what-we-make-section, .wafers-List-section {
      .products-list-what-we-make, .wafers-list {
        .container {
          .card-container {
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
          }
        }
      }
    }
  //}
}

@media screen and (max-width: 680px) {
  //.products {
    .products-list-what-we-make-section, .wafers-List-section {
      .products-list-what-we-make, .wafers-list {
        .products-list-what-we-make-title, .wafers-List-title {
          width: 85%;
        }
        .container {
          .card-container {
            display: inline-block;
            .card-article {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  //}
}

@media screen and (max-width: 340px) {
  //.products {
    .products-list-what-we-make-section, .wafers-List-section {
      .products-list-what-we-make, .wafers-list {
        .container {
          //margin-inline: 1rem;

          .card-data {
            width: 250px;
            padding: 1rem;
          }
        }
      }
    }
  //}
}