//MapComponent.scss

.MapComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; // Adjust based on your layout needs
    position: relative; // Ensure absolute positioning of child elements works as expected
    max-height: 40rem;

    .Map {
        position: relative;
        padding: 0;
        overflow: visible; 
        width: 100%;
        height: 100%;
        max-width: 1600px;
    
        iframe {
            width: 100%;
            height: 100%;
            border: none; // This removes the border around the map
            /* pointer-events: none; */ // Remove this to enable map interaction
        }
    }    

    .contact-us {
        background-color: #2d8ccb;
        color: #fff;
        padding: 20px;
        text-align: center;
        width: 65%;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1;
        position: absolute; // Absolute positioning to allow for centering
        margin-top: 40rem;

        p {
            margin-left: 20%;
            margin-right: 20%;
        }

        h2 {
            margin-bottom: 10px;
            font-size: 35px;
            margin-top: 1rem;
        }

        button {
            background-color: #fff;
            color: #1E3A8A;
            padding: 10px 20px;
            border-radius: 50px;
            cursor: pointer;
            margin-top: 15px;
            border: 2px solid #fff;
            margin-bottom: 2rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            padding: 16px 30px;
            min-width: 163px;
            text-align: center;
            overflow: hidden;
            position: relative;

            &:hover {
                background-color: #2d8ccb;
                color: #fff;
                transition: all .3s ease-in-out;
                text-transform: capitalize;
            }
        }
    }

    .contact-us::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url('../../images/backgroundImg/blue-bg-area.png') 100% / cover no-repeat;
        z-index: -1;
        border-radius: 12px;
    }
}
