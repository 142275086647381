// TypesOfSiliconWafer.scss

.types-of-silicon-wafer-section {
    background-color: #79A3DC;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    .types-of-silicon-wafer-container {
        background-image: url('../../../../images/backgroundImg/common-background-White.png');
        opacity: 0.07;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;  // Make sure this is below the z-index of your clickable elements
    }

    .types-of-silicon-wafer {
        padding: 3rem;
        padding: 0px;
        padding-top: 3rem;
        padding-bottom: 3rem;
        z-index: 2;  // Ensure this is above the container
        position: relative;

        .types-of-silicon-wafer-title {
            h1 {
                font-size: 2.5rem;
                margin: 0px;
                text-align: center;
            }
        }

        .types-of-silicon-wafer-div {
            display: flex;
            margin: auto;
            margin-top: 3rem;

            .types-of-silicon-wafer-list {
                width: fit-content;
                .block {
                    border: #fff solid 1px;
                    border-right: #fff solid 5px;
                    border-bottom: #fff solid 5px;
                    margin: 15px auto;
                    padding: 0px 10px;
                    width: 20rem;
                    cursor: pointer;
                    z-index: 3;  // Ensure this is above everything else
                    position: relative;  // This will help in managing z-index

                    &:hover {
                        margin-right: -15px;
                    }

                    p {
                        margin: 10px;
                    }
                }
            }

            .types-of-silicon-wafer-text {
                width: 35rem;
                height: 37rem;
                text-align: left;
                margin: auto;
                border: #fff solid 2px;

                .block {
                    margin: auto;
                    width: 90%;
                }
            }
        }
    }
}

/* Responsive Design for Mobile */

@media (max-width: 1001px) {
    .types-of-silicon-wafer-section {
        .types-of-silicon-wafer {
            .types-of-silicon-wafer-div {
                .types-of-silicon-wafer-list {
                    .block {
                        width: 15rem;
                    }
                }
                .types-of-silicon-wafer-text {
                    width: 28rem;
                    height: 39rem;
                }
            }
        }
    }
}

@media (max-width: 801px) {
    .types-of-silicon-wafer-section {
        .types-of-silicon-wafer {
            .types-of-silicon-wafer-div {
                margin-top: 2.5rem;
                display: block;
                .types-of-silicon-wafer-list {
                    width: 90%;
                    margin: auto;
                    .block {
                        width: 15rem;
                        width: inherit;
                    }
                }

                /* For dropdown menu in mobile view */
                .types-of-silicon-wafer-dropdown {
                    position: relative;
                    width: 100%;
                    width: 90%;
                    margin: auto;
                    border: 2px solid #fff;
                    
                    .dropdown-toggle {
                        width: 100%;
                        padding: 10px;
                        background-color: #fff;
                        border: 2px solid #fff;
                        color: #333;
                        cursor: pointer;
                        text-align: center;
                        font-weight: bold;
                        font-size: large;

                        img {
                            margin: auto;
                            margin-left: 25px;
                            width: 10px;
                            transform: rotate(90deg);
                        }
                    }
                    
                    .dropdown-menu {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        background-color: #fff;
                        color: #333;
                        border-top: 2px solid #000;
                        position: absolute;
                        width: 100%;
                        max-height: 13.2rem; 
                        overflow-y: auto; 
                        z-index: 10;
                        
                        li {
                          padding: 10px;
                          cursor: pointer;
                          border-bottom: 1px solid #000;
                      
                          &:hover {
                            background-color: #79A3DC;
                            color: #fff;
                          }
                        }
                      }
                      
                }

                .types-of-silicon-wafer-text {
                    width: 90%;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 501px) {
    .types-of-silicon-wafer-section {
        .types-of-silicon-wafer {
            .types-of-silicon-wafer-title {
                h1 {
                    font-size: 2rem;
                }
            }
        }
    }
}