// BuildSiliconWafer.scss
.build-sec {
    position: relative;
    margin-top: 4rem;

    .container {
      //--bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      width: 100%;
      padding-right: calc(var(--bs-gutter-x)* .5);
      padding-left: calc(var(--bs-gutter-x)* .5);
      margin-right: auto;
      margin-left: auto;
    }
    .center-cont {
      margin-bottom: 58px;
      max-width: 944px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      position: relative;

      h2 {
        font-weight: 400;
        font-size: 40px;
        line-height: 1;
        font-family: var(--arialbold);
        position: relative;
        text-transform: capitalize;
        color: #02263e;
        font-weight: bold;
      }
      h2::before {
        content: "";
        display: inline-block;
        width: 127px;
        height: 1px;
        display: inline-block;
        background: linear-gradient(-90deg, #2d8ccb 0, rgba(255, 255, 255, 0) 62.2%);
        opacity: .7;
        margin-right: 18px;
        vertical-align: middle;
      }
      h2::after {
        content: "";
        display: inline-block;
        width: 127px;
        height: 1px;
        display: inline-block;
        background: linear-gradient(90deg, #2d8ccb 0, rgba(255, 255, 255, 0) 62.2%);
        opacity: .7;
        margin-left: 18px;
        vertical-align: middle;
      }

      p {
        margin: 0 0 22px;
        font-family: 'Arial', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        font-style: normal;
        color: #53778f;
        line-height: 1.5;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  
    .build-top-area {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      border: 10px solid #d6d6d6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      position: relative;
      padding: 20px;
  
      h3 {
        font-family: var(--arialbold);
        margin-bottom: 0;
        text-transform: capitalize;
        margin: 0 25px;
        padding: 0;
        color: #02263e;
      }
  
      .build-top-area-img {
        margin-bottom: 15px;
      }
  
      &::before {
        content: '';
        position: absolute;
        top: -58px;
        left: 48%;
        transform: translateX(-50%);
        width: 338px;
        height: 338px;
        z-index: -1;
      }
    }
  
    .build-card-row {
      justify-content: center;
        //--bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1* var(--bs-gutter-y));
        margin-right: calc(-.5* var(--bs-gutter-x));
        margin-left: calc(-.5* var(--bs-gutter-x));
        box-sizing: border-box;

        font-family: 'Arial', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        font-style: normal;
        color: #53778f;
        line-height: 1.5;
        box-sizing: border-box;

        .cmn-card-2 {
          text-align: center;
          padding: 30px;
          position: relative;
        }
  
      .build-card-col:nth-child(odd) .cmn-card-2 .build-top-area::before {
        background: url('../../images/BuildSiliconWafer/half-vector.svg') center top/contain no-repeat;
      }

      .build-card-col:nth-child(odd) .cmn-card-2 .build-top-area::before {
        content: "";
        position: absolute;
        background: url('../../images/BuildSiliconWafer/half-vector.svg') center top / contain no-repeat;
        top: -58px;
        left: 48%;
        transform: translateX(-50%);
        width: 338px;
        height: 338px;
        z-index: -1;
      }
  
      .build-card-col:nth-child(odd) .cmn-card-2 .build-top-area {
        margin-bottom: 40px;
      }

      .build-card-col:nth-child(2n) .cmn-card-2 .build-top-area::before {
        content: "";
        position: absolute;
        background: url('../../images/BuildSiliconWafer/rev-half-vector.svg') center top / contain no-repeat;
        bottom: -74px;
        left: 50%;
        transform: translateX(-50%);
        width: 338px;
        height: 338px;
        z-index: -1;
        margin-block-start: auto;
    }
  
      .build-card-col:nth-child(2n) .cmn-card-2 .build-top-area {
        margin-bottom: 80px;
        margin-top: -40px;
  
        &::before {
          background: url('../../images/BuildSiliconWafer/rev-half-vector.svg') center top/contain no-repeat;
          bottom: -74px;
          left: 50%;
          transform: translateX(-50%);
        }
  
        .build-class-no {
          color: #28387d;
          top: 39%;
        }
      }
      .build-top-area-img {
        margin-bottom: 15px;
        img {
          max-width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }
      .build-top-area h3 {
        font-family: var(--arialbold);
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }
  
    .build-class-no {
      font-family: "Montserrat", sans-serif;
      font-size: 50px;
      font-weight: 700;
      position: absolute;
      left: 113%;
      top: 64%;
      transform: translateY(-50%);
      color: #2d8ccb;
    }
  
    .btn-center {
      margin-top: 15px;
    }
  
    .vector-pattern-bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: .8;
      max-width: 246px;
      width: 26%;
      img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
    button {
      background-color: #1e3a8a;
      color: #fff;
      padding: 10px 20px;
      //border: none;
      border-radius: 50px;
      cursor: pointer;
      margin-top: 1rem;
      margin-bottom: 0rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      padding: 16px 30px;
      min-width: 163px;
      border-radius: 26px;
      text-align: center;
      overflow: hidden;
      position: relative;
      border: 2px solid #1e3a8a;
  
      i {
        margin-left: 10px;
        color: #fff;
      }
  
      &:hover {
          background-color: #fff;
          color: #1e3a8a;
          //border: #fff;
          border: 2px solid #1e3a8a;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          -ms-transition: .3s ease-in-out;
          -o-transition: .3s ease-in-out;
          transition: all .3s ease-in-out;
          text-transform: capitalize;
      }
    }
  }
  
@media (max-width: 1199px) {
  .build-sec{
    .center-cont{
      .h2-title, h2 {
        font-size: 32px;
      }
    }
    .build-top-area {
      width: 130px;
      height: 130px;
    }
    .build-card-row {
      .build-card-col:nth-child(odd) .cmn-card-2 .build-top-area::before {
        top: -47px;
        width: 260px;
        height: 260px;
      }
      .build-card-col:nth-child(2n) .cmn-card-2 .build-top-area::before {
        width: 260px;
        height: 260px;
        bottom: -62px;
        margin-block-start: auto;
      }
      .build-top-area h3 {
        font-size: 16px;
      }
    }
    .build-class-no {
      left: 108%;
    }
    button {
      background-color: #1e3a8a;
      color: #fff;
      padding: 10px 20px;
      //border: none;
      border-radius: 50px;
      cursor: pointer;
      margin-top: 1rem;
      margin-bottom: 0rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      padding: 16px 30px;
      min-width: 163px;
      border-radius: 26px;
      text-align: center;
      overflow: hidden;
      position: relative;
      border: 2px solid #1e3a8a;
  
      i {
        margin-left: 10px;
        color: #fff;
      }
  
      &:hover {
          background-color: #fff;
          color: #1e3a8a;
          //border: #fff;
          border: 2px solid #1e3a8a;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          -ms-transition: .3s ease-in-out;
          -o-transition: .3s ease-in-out;
          transition: all .3s ease-in-out;
          text-transform: capitalize;
      }
    }
  }
}
@media (max-width: 991px) {
  .build-sec{
    .center-cont{
      p {
        font-size: 16px;
      }
      .h2-title, h2 {
        font-size: 29px;
        line-height: normal;
        padding: 0 10px;
      }
      h2::after, h2::before {
        width: 100px;
      }   
    }
  }
}
@media (min-width: 768px) {
  .build-sec{
    .center-cont{
      .container {
        max-width: 720px;
      }
    }
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
}
@media (max-width: 651px) {
  .build-sec{
    .center-cont{
      h2::after, h2::before {
        display: none;
      }   
    }
  } 
}
@media (min-width: 576px) {
  .build-sec{
      .container {
        max-width: 1000px;
      }
  } 
}