// QuoteRequestFormSection.scss

.quote-request-form-section {
  background-color: #f9fbff;
  background-color: #f1f5fe;
  text-align: center;
  width: 100%;

  .quote-request-form-sections {
    max-width: 1200px;
  }

  .title {
    text-align: center;
    font-size: 2rem;
    color: #002147;
    margin-bottom: 2.5rem;
    margin-top: 0px;
    padding-top: 4rem;
  }

  .quote-request-form-sections {
    /*background-color: #f9fbff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px #d0dced;*/
    width: 60%;
    margin: auto;
    margin-bottom: 0px;
    padding-bottom: 6rem;
    text-align: left;

    .personal-information,
    .product-information,
    .additional-information {
      margin-bottom: 2rem;

      h2 {
        font-size: 1.5rem;
        color: #79A3DC;
        color: #4f74a5;
        margin-bottom: 1rem;
      }

      .form-group {
        display: flex;
        flex-direction: column;
        //margin-bottom: 1rem;

        label {
          font-weight: bold;
          color: #333;
          margin-bottom: 0.5rem;
          font-size: large;
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="file"],
        textarea {
          padding: 0.5rem;
          border: 1px solid #ccc;
          border: 1px solid #555a67;
          background-color: #fff;
          //border: 1px solid #4f74a5;
          border-radius: 5px;
          font-size: 18px;
          padding: 16px;
          //margin-bottom: 1rem;
        }

        .options {
          display: flex;
          flex-wrap: wrap;

          label {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            width: 10rem;
            //margin-bottom: 10px;

            input {
              margin-right: 0.5rem;
            }
          }

          input[type="text"] {
            margin: 0rem 0rem 0rem 1rem;
            width: 100%;
            font-size: 15px;
            padding: 10px;
            border: none;
            border-bottom: 1px solid #555a67;
            background-color: transparent;
            padding-bottom: 5px;
          }
        }
      }
    }

    .personal-information .form-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr); // Creates two equal columns
      gap: 0.8rem;

      // input {
      //   width: 93%; // Ensures inputs take full width of their grid cell
      // }
    }

    .product-information .form-flax {
      display: flex;
      width: 100%;
      display: grid;
        //grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); // Responsive columns
        grid-template-columns: repeat(2, 1fr);
        //gap: 1rem;
        column-gap: 1rem;
      .form-group {
        display: inline-grid;
      }
    }

    .product-information .form-group {
      //margin-top: 1rem;

      .sub-title {
        font-size: large;
        padding-top: 20px;
      }

      label {
        font-size: 1.02rem;
      }

      .material label {
        width: 12.5rem;
      }

      .diameter label {
        width: 9rem;
      }

      .type label {
        width: 6rem;
      }

      .dopant label {
        width: auto;
      }

      .orientation label {
        width: 6rem;
      }

      .flat-notch label {
        width: 8rem;
      }

      .finish label {
        width: 16rem;
      }

      .film label {
        width: 16.6rem;
      }

      .material{
        &::before {
          content: 'Material';
        }
      }
      .diameter{
        &::before {
          content: 'Diameter';
        }
      }
      .type{
        &::before {
          content: 'Type';
        }
      }
      .dopant{
        &::before {
          content: 'Dopant';
        }
      }
      .orientation{
        &::before {
          content: 'Orientation';
        }
      }
      .flat-notch{
        &::before {
          content: 'Flat/Notch';
        }
      }
      .finish{
        &::before {
          content: 'Finish';
        }
      }
      .film {
        &::before {
          content: 'Film (Need film on wafers?)';
        }
      }

      .material,
      .diameter,
      .type,
      .dopant,
      .orientation,
      .flat-notch,
      .finish,
      .film {
        border: 1px solid #555a6796;
        padding: 20px;
        position: relative;
        margin-top: 20px;
        border-radius: 10px;
        display: ruby;
        padding-top: 32px;

        // Style for the "Material" text in top half border
        &::before {
          position: absolute;
          top: -12px;
          left: 20px;
          background-color: #f1f5fe; // Matches the background color
          padding: 0 10px;
          font-size: 1.2rem;
          font-weight: bold;
          color: #333;
          //font-style: italic;
        }

        label {
          display: block; // Ensures labels are stacked
          margin-bottom: 10px;
          font-size: 1rem;
        }

        input[type="radio"] {
          //vertical-align: baseline;
          float: left;
        }

        label {
          margin-right: 1rem;
          display: flex;
          align-items: center; // Aligns the text and the radio button vertically
          //width: 10rem; // Adjust this width as necessary to fit your design
          margin-bottom: 10px;
      
          input[type="radio"] {
            margin-right: 0.5rem; // Space between radio button and text
          }
        }

        .other {
          display: flex;
          align-items: center;
          width: auto;

          input[type="text"] {
            margin-left: 10px;
            width: auto;
            font-size: 0.9rem;
            padding: 5px;
            border-bottom: 1px solid #555a67;
            background-color: transparent;
          }
        }
      }
      .finish,
      .film {
        margin-top: 35px;
      }
    }

    .button {
      display: flex;
      align-items: center;

      .submit-button {
        width: 50%;
        padding: 0.75rem;
        background-color: #79A3DC;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: auto;
        margin-top: 1.5rem;
      }

      .submit-button:hover {
        background-color: #030538;
      }
    }
  }
}

@media (max-width: 1370px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      width: 70%;
      // .personal-information {
      //   .form-group {
      //     grid-template-columns: 1fr;
      //   }
      // }
    }
  }
}

@media (max-width: 1140px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      width: 80%;
      // .personal-information {
      //   .form-group {
      //     grid-template-columns: 1fr;
      //   }
      // }
    }
  }
}

@media (max-width: 1010px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      width: 90%;
      // .personal-information {
      //   .form-group {
      //     grid-template-columns: 1fr;
      //   }
      // }
    }
  }
}

@media (max-width: 768px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      .personal-information {
        .form-group {
          grid-template-columns: 1fr;

          input {
            width: 95%;
          }
        }
      }
      .product-information {
        .form-flax {
          display: block;
        }
      }

      .button {
        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      .personal-information {
        .form-group {
          input {
            width: 94%;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      .personal-information {
        .form-group {
          input {
            width: 93%;
          }
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      .personal-information {
        .form-group {
          input {
            width: 90%;
          }
        }
      }
      .product-information {
        .form-group {
          .material,
          .diameter,
          .type,
          .dopant,
          .orientation,
          .flat-notch,
          .finish,
          .film {
            .other {
              display: block;
              input[type=text] {
                margin-top: 10px;
                width: 95%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      .personal-information {
        .form-group {
          input {
            width: 87%;
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .Comen-top-banner .Comen-top-banner-cont h1 {
      line-height: 1;
      margin-bottom: 2rem;
  }
}

@media (max-width: 351px) {
  .Comen-top-banner .Comen-top-banner-cont h1 {
      margin-bottom: 0.9rem;
  }
}

@media (max-width: 350px) {
  .quote-request-form-section {
    .quote-request-form-sections {
      .personal-information {
        .form-group {
          input {
            width: 87%;
          }
        }
      }

      .product-information .form-group {

        .material,
        .diameter,
        .type,
        .dopant,
        .orientation,
        .flat-notch,
        .finish,
        .film {
          label {
            width: 100%;
          }
        }
      }
    }
  }
}