//Homeabout.scss
.who-we-are-sec {
  position: relative;
  //padding: 60px 0;
  padding: 30px 7vw;
  //background-color: #f8f8f8;
  background-color: ghostwhite;
}

.who-we-are-sec::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../images/backgroundImg/background_shape_1.png') 100% / 100% no-repeat;
  opacity: .5;
  z-index: -1;
}

.who-we-are-sec {
  .container {
    max-width: 8000px;
    margin: 0 auto;
  }

  .who-we-are-row {
    display: flex;
    align-items: center;
  }

  .who-we-are-col {
    padding: 15px;
    width: 60%;
  }

  .who-we-are-img-area {
    position: relative;
    text-align: center;
  }

  .who-we-are-img-area img {
    width: 90%;
    height: auto;
    border-radius: 50%;
  }

  .vertical-txt {
    position: absolute;
    top: 50%;
    left: -60px;
    transform: translateY(-50%);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 60px;
    //color: #ededed;
    color: #00000014;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    white-space: nowrap;
    letter-spacing: 2px;
  }

  .who-we-are-text-area {
    text-align: left;
  }

  .who-we-are-text-area h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1px;
  }

  .who-we-are-text-area p {
    font-size: 1.05rem;
    color: #666;
    margin-bottom: 20px;
  }

  .who-we-are-text-area h3 {
    font-size: 1.5rem;
  }

  .who-we-are-text-area ul {
    font-size: 1.2vw;
    padding-left: 20px;
  }

  .who-we-are-text-area ul li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }

  .who-we-are-text-area {
    button {
      background-color: #1e3a8a;
      color: #fff;
      padding: 10px 20px;
      //border: none;
      border-radius: 5px;
      cursor: pointer;
      //margin-top: 1rem;
      //margin-bottom: 0rem;
      margin: 2rem 1rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      padding: 13px 30px;
      min-width: 163px;
      //border-radius: 26px;
      text-align: center;
      overflow: hidden;
      position: relative;
      border: 2px solid #1e3a8a;

      i {
        margin-left: 10px;
        color: #fff;
      }

      &:hover {
        background-color: #fff;
        color: #1e3a8a;
        //border: #fff;
        border: 2px solid #1e3a8a;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: all .3s ease-in-out;
        text-transform: capitalize;
      }
    }
  }
}

//   .cmn-btn {
//     display: inline-block;
//     padding: 10px 20px;
//     background-color: #007bff;
//     color: #fff;
//     text-transform: uppercase;
//     font-size: 14px;
//     font-weight: bold;
//     border-radius: 5px;
//     text-decoration: none;
//     transition: background-color 0.3s ease;
//   }

//   .cmn-btn:hover {
//     background-color: #0056b3;
//   }

//   .cmn-btn i {
//     margin-left: 10px;
//   }

@media (min-width: 1199px) {
  .who-we-are-sec {
    .container {
      max-width: 1000px;
    }
  }
}

@media (max-width: 992px) {
  .who-we-are-sec {
    .vertical-txt {
      left: -30px;
      font-size: 40px;
    }

    .who-we-are-img-area img {
      width: 80%;
    }

    .who-we-are-col {
      width: auto;
    }
  }
}

@media (max-width: 992px) {
  .who-we-are-sec {
    .who-we-are-row {
      display: block; // Override the flex behavior
      width: 100%;
    }

    .who-we-are-col .col-lg-7,
    .who-we-are-col .col-lg-5 {
      width: 100% !important;
      padding: 15px !important;
    }

    .vertical-txt {
      display: none;
    }

    .who-we-are-img-area img {
      width: 40%;
      //margin-left: -2rem;
    }

    .who-we-are-text-area {
      margin-right: 0px;
    }

    .who-we-are-text-area h2 {
      margin-bottom: 1px;
      font-size: 1.7rem;
      margin-top: 10px;
    }

    .who-we-are-text-area p {
      font-size: 1rem;
      margin-bottom: 20px;
    }

    .who-we-are-text-area h3 {
      font-size: 1.4rem;
    }

    .who-we-are-text-area ul li {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    .who-we-are-text-area {
      .btn-center {
        text-align: center;

        button {
          width: 30vw;
          margin-top: 3rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .who-we-are-sec {
    .who-we-are-row {
      display: block; // Override the flex behavior
      width: 100%;
    }

    .who-we-are-col .col-lg-7,
    .who-we-are-col .col-lg-5 {
      width: 100% !important;
      padding: 15px !important;
    }

    .vertical-txt {
      left: -30px;
      font-size: 8vw;
    }

    .who-we-are-img-area img {
      width: 70%;
      //margin-left: -2rem;
    }

    .who-we-are-text-area {
      margin-right: 0px;
    }

    .who-we-are-text-area h2 {
      margin-bottom: 1px;
      font-size: 2.3rem;
      margin-top: 10px;
    }

    .who-we-are-text-area p {
      font-size: 1.1rem;
      margin-bottom: 20px;
    }

    .who-we-are-text-area h3 {
      font-size: 1.5rem;
    }

    .who-we-are-text-area ul li {
      font-size: 0.98rem;
      margin-bottom: 10px;
    }

    .who-we-are-text-area {
      .btn-center {
        text-align: center;

        button {
          width: 60vw;
        }
      }
    }
  }
}