// ComenTopBanner.scss

@import url('https://fonts.cdnfonts.com/css/brother-signature');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

/* Inner Banner Area */
.Comen-top-banner {
  //background-color: #79A3DC;
  // //background-color: #002960;
  width: 100%;
  height: 32rem;
  display: flex;
  align-items: center; // Vertically center the content
  justify-content: center; // Horizontally center the content

  .container {
    //background-image: url('../../images/backgroundImg/common-background-White.png');
    background-image: url('../images/backgroundImg/common-background-White-2.png');
    opacity: 0.07;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    width: 100%;
    height: 100%;
  }

  .scroll-down-arrow {
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    margin-top: 1rem;
    animation: bounce 2s infinite;
    position: absolute;
    align-self: flex-end;
    margin-bottom: 3rem;
    img {
      width: 1.3rem;
      transform: rotate(90deg);
    }
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }  

  .Comen-top-banner-cont {
    width: 90%;
    text-align: center; // Center text within the container
    position: absolute;
    margin-top: -3rem;

    h1 {
      margin: 0;
      font-size: 3.1rem;
      line-height: 1.5; // Adjust line height for better spacing
      color: #fff;
    }

    .cursive-font {
      //font-family: 'Pacifico', cursive;
      font-family: 'cursive';
      //font-size: 2rem;
      display: inline; // Display text inline with "OF"
      //color: #92b5f3;
      color: #000;
      font-variant: small-caps;
      font-weight: bold;
      font-size: 4rem;
      line-height: 1; // This sets the line height to be equal to the font size
      font-family: sans-serif;
    }
  }
}

@media (max-width: 768px) {
  .Comen-top-banner {
    .Comen-top-banner-cont {
  
      h1 {
        font-size: 2.5rem;
        line-height: 2;
        letter-spacing: 2px;
      }
  
      .cursive-font {
        font-size: 3rem;
        line-height: 2.6rem;
      }
    }
  }
}

@media (max-width: 377px) {
  .Comen-top-banner {
    .Comen-top-banner-cont {
  
      h1 {
        font-size: 2rem;
        line-height: 3;
        letter-spacing: 2px;
      }
  
      .cursive-font {
        font-size: 2.5rem;
        line-height: 2rem;
      }
    }
  }
}

@media (max-width: 269px) {
  .Comen-top-banner {
    .Comen-top-banner-cont {
  
      h1 {
        font-size: 2rem;
        line-height: 1.6;
        letter-spacing: 2px;
      }
  
      .cursive-font {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }
}