// ContactForm.scss

.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
  
  .contact-form {
    background: #f9fbff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px #d0dced;
    max-width: 500px;
    width: 100%;
    //display: flex;

    .form-group {
      margin-bottom: 1rem;
      margin-right: 2rem;
      width: 100%;
    }

    .form-row {
    display: flex;
    justify-content: space-between;
  }

  .small-form-group {
    width: 48%;
  }
    
    label {
      display: block;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #333;
    }
    
    label span {
      color: #ff4d4d;
    }
    
    .error {
      color: #ff4d4d;
    }

    input,
    textarea {
      width: -webkit-fill-available;
      padding: 0.75rem;
      border: 1px solid #d9e2ec;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      background-color: #fff;
    }
    
    textarea {
      height: 100px;
      resize: none;
    }
    
    input:focus,
    textarea:focus {
      border-color: #79A3DC;
    }
    
    .submit-button {
      width: 100%;
      padding: 0.75rem;
      background-color: #79A3DC;
      color: white;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-top: 1.5rem;
    }
    
    .submit-button:hover {
      background-color: #030538;
    }
  }
  
}

@media (max-width: 982px) {
    .contact-form-container {
      display: block;
      width: 90%;
      margin: auto;
      margin-top: 3rem;
      .contact-form {
        max-width: 100%;
        width: 85%;
        margin: auto;
      }
    }
}

@media (max-width: 572px) {
  .contact-form-container {
    .contact-form {
        width: auto;
        .small-form-group {
          width: 100%;
        }
        .form-row {
          display: block;
        }
    }
  }
}