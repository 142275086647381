// Products.scss

.products {
    .who-we-are-sec {
        padding-bottom: 40px;

        .who-we-are-col {
            .who-we-are-text-area {
                h2 {
                    font-size: 3rem;
                }

                p {
                    font-size: 1.2rem;
                    text-align: justify;
                    text-justify: inter-word;
                }
            }
        }
    } 
}
